/*
 * Copyright ish group pty ltd 2022.
 *
 * This program is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License version 3 as published by the Free Software Foundation.
 *
 *  This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.
 */

export const shouldAsyncValidate = ({trigger, pristine, initialized}) => {
  switch (trigger) {
    case "blur":
    case "change":
      return true;
    case "submit":
      return !pristine || !initialized;
    default:
      return false;
  }
};